import React from "react";
import { Button, Modal, Alert } from "react-bootstrap";
import { useLang } from "language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faSave, faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {tarifasBasicoLarga, tarifasBasicoCorta, tarifaspProfesionalLarga} from './utils'

export const ModalTablaTarifas = (props) => {
    const locale = useLang();
    const { data, handleClose } = props;

    const TarifasTable = ({ title, tarifas }) => (
        <table className="table table-bordered table-sm table-striped shadow-sm">
            <thead>
                <tr className="text-center">
                    <th>{locale('TARIFA_MODAL_TABLE_TITULO1')}</th>
                    <th>{locale('TARIFA_MODAL_TABLE_TITULO2')}</th>
                    <th>{locale('TARIFA_MODAL_TABLE_TITULO3')}</th>
                </tr>
            </thead>
            <tbody>
                {tarifas.map((row, index) => (
                    <tr key={index} className="text-center">
                        <td>{row.numDetectores}</td>
                        <td>{row.tarifaGeneral}</td>
                        <td>{row.tarifaColegido}</td>
                    </tr>
                ))}
                <tr className="text-center">
                    <td><b>{locale('TARIFA_MODAL_TABLE_DETECTOR_ADICIONAL')}</b></td>
                    <td>€ 30</td>
                    <td>€ 30</td>
                </tr>
            </tbody>
        </table>
    );

    return (
        <Modal show={data.value} size="xl" bsPrefix="modal" centered className="border-0 shadow">
            <Modal.Body className="p-2">
                <h5 className="text-center mt-3" id="exampleModalLabel">{data.action === 'PROFESIONAL_LARGA' ? locale('TEST_RADON_PROFESIONAL') : locale('HOME_SECTION1_BOX1_SUBTITLE')}</h5>
                <p className="text-center fw-bold">
                    {data.action === 'BASICO_LARGA' &&
                        locale('HOME_RADON_BASICO_TEXT_SPAN2')
                    }

                    {data.action === 'BASICO_CORTA' &&
                        locale('HOME_RADON_BASICO_TEXT_SPAN1')
                    }

                    {data.action === 'PROFESIONAL_LARGA' &&
                        locale('HOME_RADON_BASICO_BOX2_TITLE')
                    }
                </p>
                <hr className="text-primary"/>
                 <div className="row d-flex align-items-center justify-content-center">
                 <div className="col-lg-9">
                        <p><strong>{locale('HOME_TARIFA_DATALLE_1')}</strong></p>
                        <p><strong>{locale('HOME_TARIFA_DATALLE_2')}</strong></p>
                        {data.action === 'BASICO_LARGA' && (
                            <TarifasTable title="Básico Larga" tarifas={tarifasBasicoLarga} />
                        )}

                        {data.action === 'BASICO_CORTA' && (
                            <TarifasTable title="Básico Corta" tarifas={tarifasBasicoCorta} />
                        )}

                        {data.action === 'PROFESIONAL_LARGA' && (
                            <TarifasTable title="Profesional Larga" tarifas={tarifaspProfesionalLarga} />
                        )}
               </div>
             </div>

             <div className="text-center mt-2 mb-3">
                    <Button variant="primary" className="form-text my-0" onClick={handleClose}>
                        {locale('HOME_BTN_CERRAR')}
                    </Button>
                </div>
            </Modal.Body>    
        </Modal>
    );
};
